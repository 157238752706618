.home {
    // padding: 2rem 3rem;
    
    .content {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        flex-wrap: wrap;
        // color: rgba(0, 0, 0, .2);

        .left {
            flex: 0 1 320px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 0 1rem 1rem 1rem;
            gap: 1rem;
            height: 500px;
            background-color: #fff;
            box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);

            .filters {
                // background-color: red;
                height: 65%;
            }
            // .ad {
            //     background-color: gray;
            //     height: 35%;
            //     display: flex;
            //     color: #fff;
            //     font-weight: 700;
            //     align-items: center;
            //     justify-content: space-around;
            // }
        }
        .right {
            flex: 0 1 700px;
            // height: 500px;
            background-color: #fff;
            box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
            overflow-y: hidden;

            .fbtn {
                display: none;
            }
            
            .posts {
                // height: 100%;
                
                padding: .5rem 1rem;
                position: relative;
                margin-top: -1rem;

                .post {
                    color: #404047;
                    margin-bottom: 1rem;
                    border: 1px solid rgba(128, 128, 128, .2);
                    padding: 1rem;
                    border-radius: 10px;
                    border: 1px solid rgb(238, 238, 238);
                    background-color: #fff !important;

                    .button {
                        padding: 10px 25px;
                            box-sizing: border-box;
                            width: fit-content;
                            border-radius: 5px;
                            // height: 30px;
                            outline: none;
                            border: none;
                            font-size: 14px;
                            font-weight: 600;
                            color: #fff;
                            cursor: pointer;
                            letter-spacing: 1px;
                            transition: 250ms ease;
                            background-color: #009942;
                    }
                    

                    .top {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .lef {
                            display: flex;
                            align-items: center;
                            gap: .8rem;

                            img {
                                transform: scale(1.2);
                                margin-left: .3rem;
                            }

                        }
                        .date {
                            font-size: .8rem;
                            font-weight: 600;
                        }
                    }

                    .middle {
                        margin: 1rem 0;
                        

                        .picks {
                            // background-color: "red !important";
                        //    border: 1px solid rgb(238, 238, 238);
                           border-radius: 6px 6px 0 0;
                           height: 210px;
                           overflow-y: hidden;
                           position: relative;

                           .platform-banner {
                            height: 15px;
                            background-color: #e41827;
                            background-color: "#f3f3f3";
                            border-radius: 6px 6px 0 0;
                            }
                            
                            .pick {

                                .cont {
                                    display: flex;
                                    align-items: baseline;
                                    padding: .5rem;
                                    justify-content: space-between;

                                    .leftx {

                                        .uptxt {
                                            display: flex;
                                            align-items: center;
                                            gap: .3rem;
                                            margin-bottom: .3rem;
                                        }
                                        .midtxt {
                                            margin-bottom: .1rem;
                                        }
    
                                    }
                                    .odd {
                                        font-weight: 600;
                                    }
                                }
                            }
                        }
                        .more {
                            font-size: .8rem;
                            text-align: center;
                            margin: .5rem auto 0 auto;
                            font-weight: 600;
                            cursor: pointer;
                            background: #007030;

                            svg {
                                margin-left: .3rem;
                            }
                        }
                        
                    }

                    .bottom {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        // margin-top: 2rem;

                        .lefty {
                            .ttxt {
                                font-size: .8rem;
                                margin-bottom: .3rem;
                                font-weight: 500;
                            }
                            .btxt {
                                font-weight: 600;
                                background-color: #fff;
                                padding: .4rem 5rem;
                            }
                        }
                        .righty {
                            display: flex;
                            gap: .5rem;

                            button {
                                border-radius: 80px;
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                gap: .4rem;
                                border: none;
                                outline: none;
                                padding: .5rem 1rem;
                                font-size: 13px;
                                font-weight: 600;
                            }
                            .copy {
                                background-color: #007030 !important;
                                // background-color: #002e14 !important;
                                color: #fff;
                            }
                            .share {
                                background-color: #D4D3D8;
                                color: #3F3F46;
                            }
                        }
                    }
                }
            }
        }
        .last {
            flex: 0 1 250px;
            height: 500px;
            background-color: gray;
            display: flex;
            color: #fff;
            font-weight: 700;
            align-items: center;
            justify-content: space-around;
            // box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
        }
    }
}

@media screen and (max-width: 480px) {
    .home {
        .content {
            

            .left {

            }
            .right {
                
            }
        }
    }
}
@media screen and (max-width: 700px) {
    .home {
        padding: .5rem 0;
        .content {
            padding: 1rem 0;

            .left {
                display: none;
            }
            .right {
                .posts {
                    padding: .5rem 0;
                }
            }
            .last {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 1116px) {
    .home {
        .content {
            .left {
                display: none;
            }
            .right {
                .fbtn {
                    display: block;
                }
            }
        }
    }
}