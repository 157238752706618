.navbar {
    // padding: 1.8rem 3rem;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    color: #27272A;
    // box-shadow: 0px 10px 14px rgba(17, 17, 17, 0.06);

    .menu {
        display: none;
    }

    
    .libtn {
        padding: .5rem 1.5rem;
        border-radius: 24px;
        color: #fff;
        font-weight: 600;
        cursor: pointer;
        font-size: 13px;
        background-color: #E66F15;
        font-family: 'Clash Display', sans-serif !important;
        transition: 250ms ease;
        // margin-left: 1rem;
        white-space: nowrap;
        list-style: none;

        &:hover {
            background-color: #ab4e07;
        }
    }

    .links {
        display: flex;
        align-items: center;
        gap: 4rem;
        justify-content: "space-between";

        .libtn {
            padding: .5rem 1.5rem;
            border-radius: 24px;
            color: #fff;
            font-weight: 600;
            cursor: pointer;
            font-size: 13px;
            background-color: #E66F15;
            font-family: 'Clash Display', sans-serif !important;
            transition: 250ms ease;
            // margin-left: 1rem;
            white-space: nowrap;
            list-style: none;

            &:hover {
                background-color: #ab4e07;
            }
        }

        .li {
            list-style: none;
            font-weight: 500;
            cursor: pointer;
            position: relative;

            &::after {
                content: "";
                width: 100%;
                height: 1.5px;
                background-color: rgb(71, 71, 71);
                border-radius: 4px;
                position: absolute;
                left: 0;
                bottom: 0;
                transform: scaleX(0);
                transform-origin: left;
                transition: transform .25s ease;
            }

            &:hover::after {
                transform: scaleX(1);
            }
        }
    }
}

.nav {
    justify-content: left;
    width: 100%;
    // margin-left: 17px;
    margin-top: 35px;

    .hov {
        display: none;
        animation: pulse 1s linear 1000;
    }

    .lg {
        transition: 250ms ease;
        &:hover {
            background-color: #c5d381;
        }
    }
  
    .list-item {
      color: #1A1A1A;
      text-decoration: none;
      display: block;
      border-radius: 10px;
      transition: 250ms ease;
      margin: 3px auto;
      padding: 12px 13px;
      font-size: .9rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      border: none;
      white-space: nowrap;

      &:focus {
        border: none;
        outline: none;
      }

      &:hover {
        .hov {
            display: block;
        }
      }
    }
    img {
    //   margin-right: 18px;
      font-size: 1.3em;
    }
    .img {
    //   margin-right: 18px;
      font-size: 1.3em;
    }
  
  }
  

// @media screen and (max-width: 480px) {
//     .navbar {
//         img {
//             // width: 30%;
//         }
//     }
// }

// @media screen and (max-width: 700px) {
//     .navbar {
//         .menu {
//             display: block;
//         }
//         img {
//             width: 25%;
//         }
//         .links {
//             display: none;
//         }
//     }
// }

// @media screen and (max-width: 1116px) {
//     .navbar {
        
//     }
// }

@keyframes pulse {
    0% {
        transform: translateX(0)
    }
    50% {
        transform: translateX(-.5rem)
    }
    100% {
        transform: translateX(0)
    }
}