.auto-complete {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.field-div {
    width: 100%;
}
.location-search-input {
    width: 100%;
    border: 1px solid #B0B4B0;
    border-radius: 20px;
    padding: .6rem 3rem .6rem 1rem;
    outline: none;
    font-size: .9rem;
}
.location-search-input::placeholder {
    color: #8C8C8C;
    font-size: .9rem;
}
.location-search-input:focus {
    border: 1px solid #E66F15;
    
}
.location-search-input:active {
    border: 1px solid #E66F15;
    
}