.button {
        padding: 10px 25px;
        box-sizing: border-box;
        width: 100%;
        border-radius: 18px;
        /* height: 30px; */
        outline: none;
        border: none;
        font-size: 15px;
        font-weight: 600;
        color: #fff;
        cursor: pointer;
        /* letter-spacing: 1px; */
        transition: 250ms ease;
        background-color: #E66F15;
}