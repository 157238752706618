// Colors
$green: #7ac142;
$white: #fff;

// Misc
$curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);

.pay-success {
    padding: 8rem 3rem 5rem 3rem;
    text-align: center;

    img {
        width: 10%;
        margin-bottom: 2rem;
    }
    .para {
        margin-top: 2rem;
        color: #41711c;
        font-weight: 600;
    }

    .checkmark {
        margin: 0 auto;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        display: block;
        stroke-width: 2;
        stroke: $white;
        stroke-miterlimit: 10;
        box-shadow: inset 0px 0px 0px $green;
        animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
      }
      
      .checkmark__circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: $green;
        fill: none;
        animation: stroke .6s $curve forwards;
      }
      
      .checkmark__check {
        transform-origin: 50% 50%;
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        animation: stroke .3s $curve .8s forwards;
      }
      
      
}

@keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  
  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px $green;
    }
  }

@media screen and (max-width: 480px) {
    .pay-success {
        img {
            width: 30%;
        }
    }
}