* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Work Sans', sans-serif; */
  font-family: 'Creato Display', sans-serif;
}

.App {
  /* position: relative; */
  /* height: 100vh; */
  overflow-x: hidden;
}

.ad-banner {
  background-color: gray;
  position: absolute;
  height: 80px;
  width: 100%;
  bottom: 0;
  justify-content: space-around;
  display: flex;
  align-items: center;
  font-weight: 700;
  color: #fff;
}